'use client';

import { Popover } from '@headlessui/react';
import { CircularLoader } from '../CircularLoader';
import InfoIcon from '/public/images/icons/info.svg';

export interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  loading?: boolean;
  type?: 'button' | 'submit';
  variant?:
    | 'primary'
    | 'primary-outlined'
    | 'secondary'
    | 'info'
    | 'info-outlined'
    | 'error'
    | 'text'
    | 'neutral'
    | 'base'
    | 'outlined'
    | 'published'
    | 'icon'
    | 'dealcard';
  buttonSize?: 'small' | 'medium';
  disabledTooltip?: string; // Tooltip that is visible for disabled buttons (usually explaining why)
}

export const Button = ({
  children,
  type = 'button',
  variant = 'primary',
  buttonSize = 'medium',
  className = '',
  loading,
  onClick,
  disabled,
  disabledTooltip,
  ...rest
}: ButtonProps) => {
  if (disabled && disabledTooltip && !loading) {
    return (
      <Popover className={`relative ${className}`}>
        <span className="inline-flex">
          <button
            type={type}
            onClick={onClick}
            disabled={disabled}
            {...rest}
            className={`btn btn-${variant} btn-${buttonSize} rounded-r-none pr-2`}>
            {children}
          </button>

          <Popover.Button
            className={`btn btn-${variant} btn-${buttonSize} rounded-l-none border-l-0 px-2 opacity-80`}
            aria-label="Show information about why the button is disabled">
            <InfoIcon width={24} height={24} />
          </Popover.Button>

          <Popover.Panel className="tooltip">
            <p>{disabledTooltip}</p>
          </Popover.Panel>
        </span>
      </Popover>
    );
  }
  return (
    <button
      type={type}
      onClick={onClick}
      className={`btn btn-${variant} btn-${buttonSize} ${className}`}
      disabled={disabled}
      {...rest}>
      {loading ? <CircularLoader size="small" color={variant === 'secondary' ? 'secondary' : 'contrast'} /> : children}
    </button>
  );
};

'use client';

import { Inter } from 'next/font/google';
import '@/styles/globals.css';
import Image from 'next/image';
import { Button } from '@/components/Base/Button';

const inter = Inter({ subsets: ['latin'] });

// @see https://nextjs.org/docs/app/building-your-application/routing/error-handling
interface GlobalErrorProps {
  error: Error;
  reset: () => void;
}

export default function GlobalError({ error, reset }: Readonly<GlobalErrorProps>) {
  return (
    <html lang="en">
      <title>Something went wrong | Atlas Global Access</title>
      <body className={inter.className}>
        <div className="flex min-h-[100vh] flex-col items-center justify-center text-center">
          <Image
            src="/images/logo-dark.svg"
            alt="Atlas Global Access Logo"
            width={188}
            height={42}
            className="mx-auto mb-8"
          />
          <h1 data-testid="page:title">Something went wrong</h1>
          <Button onClick={() => reset()}>Try again</Button>
        </div>
      </body>
    </html>
  );
}
